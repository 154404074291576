.profile-dropdown {
  position: absolute;
  right: 0;
  top: 120%;
  box-shadow: var(--dull-white) 0 0 0.1rem;
  background-color: var(--background-color);
  color: whitesmoke;
  display: flex;
  flex-direction: column;
  border-radius: 0.2rem;
}

#profile-button-group {
  display: flex;
  background-color: transparent;
  align-items: center;
  border: none;
  color: var(--dull-white);
  gap: .3rem;
  cursor: pointer;
}

.top-nav,
.top-nav-splash {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: .6rem 2rem;
  list-style: none;
  align-items: center;
  height: 10vh;
  background-color: transparent;
  color: white;
}

.top-nav {
  /* border-bottom: 0.07rem var(--main-pink) solid; */
  /* box-shadow: var(--main-pink) 0 0 .5rem; */
  z-index: 1;
  position: relative;


}


#logo-img {
  width: 14vw;
  height: auto;
  cursor: pointer;
}

#logo-img-splash{
  width: 16vw;
  height: auto;
  cursor: pointer;
}

#profile-button {
  width: 2rem;
  height: 2rem;
  background-color: var(--main-pink);
  border-radius: 100%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--background-color);
}

#initials {
  font-weight: bold;
  color: var(--background-color);
  font-size: 1rem;
}

.nav-right {
  display: flex;
  gap: 1.5rem;
}

.login,
.signup {
  background: transparent;
  border: none;
  font-size: 1.6vw;
  font-weight: 700;
  cursor: pointer;
  color: whitesmoke;
}


.top-right {
  display: flex;
  gap: 1.5rem;
}

.create-project-button {
  border: none;
  background-color: transparent;
  color: var(--white-background);
  cursor: pointer;
  font-size: .8rem;
  padding: .6rem;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .3rem;
  opacity: .9;
}

.create-project-button:hover {
  box-shadow: var(--dull-white) 0 0 .2rem;
  opacity: 1;
  font-weight: bold;
  
}

.nav-plus {
  background-color: var(--main-pink);
  border-radius: 1rem;
  font-size: 1rem;
  padding: .1rem;
}


#profile-button:focus-visible {
  outline: none;
}

.profile-drop-container {
  position: relative;
}

.dd-info {
  border-bottom: hsla(0, 0%, 100%, 0.259) 0.01rem solid;
  padding: 0.3rem 0.7rem 0.3rem 0.4rem;
  font-size: .8rem;
  color: var(--dull-white);
  /* opacity: .9; */
}



.logout {
  background-color: transparent;
  padding: 0.3rem 0.7rem 0.3rem 0.4rem;
  border: none;
  color: var(--dull-white);
  /* opacity: .9; */
  cursor: pointer;
}

.logout:hover {
  color: var(--white-background);
  font-weight: bold;
}

.underline {
  position: relative;
  overflow: hidden;
}

.underline::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #1D2124; /* Change to match your design */
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.underline:hover::after {
  transform: scaleX(1);
  transform-origin: bottom right;
}