.dash-container {
  background-color: var(--background-color);
  color: whitesmoke;
  padding: 0 5rem;
  min-height: 90vh;
  max-height: 90vh;
  position: relative;
}

.dash-bubbles {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
}

.dash-projects {
  width: 47%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 1rem;
  box-shadow: var(--light-gray) 0rem 0rem 0.5rem;
  height: 28rem;
  background-color: #ffffff0f;
  color: var(--dull-white);
}

.dash-projects:hover {
  border: 0.1rem var(--light-gray) solid;
}

.projects-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1rem;
  padding: 0.7rem 0;
  overflow-y: scroll;
  width: 100%;
  justify-items: center;
  height: 100%;
}

.project-container {
  display: flex;
  width: 16.5rem;
  padding: 1rem;
  border-radius: 0.7rem;
  cursor: pointer;
  gap: 0.5rem;
  align-items: center;
  height: max-content;
}

.task-container {
  display: flex;
  width: 16.5rem;
  padding: 1rem;
  border-radius: 0.7rem;
  cursor: pointer;
  gap: 0.5rem;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 4.5rem;
}

.dash-welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: var(--dull-white);
}

.dash-title {
  font-size: 3rem;
}

.dash-today {
  font-size: 1rem;
}

.create-project-dash {
  border: none;
  background-color: transparent;
  color: whitesmoke;
  border-radius: 0.7rem;
  cursor: pointer;
  font-size: 1rem;
  width: 90%;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.6rem;
  opacity: 0.8;
  height: max-content;
}

.create-project-dash:hover,
.project-container:hover,
.task-container:hover {
  box-shadow: var(--light-gray) 0rem 0rem 0.3rem;
  opacity: 1;
  color: whitesmoke;
}

.project-icon {
  color: white;
  padding: 0.4rem;
  border-radius: 30%;
  min-width: 2.5rem;
  min-height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dash-project-menu {
  margin-left: auto;
  position: relative;
}

.fa-solid.fa-plus.home {
  border: whitesmoke dashed 0.1rem;
  color: whitesmoke;
  padding: 0.8rem;
  border-radius: 0.7rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dash-p-name {
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 0;
}

.dash-projects-title {
  font-size: 1.7rem;
  text-align: center;
}

.dash-task-tabs {
  display: flex;
  gap: 0.5rem;
  border-bottom: hsla(0, 0%, 100%, 0.259) 0.01rem solid;
  padding: 0.5rem 0.5rem 0 0.5rem;
}
.tab {
  position: relative;
}

.tab::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  border: none;
  border-radius: 0.3rem;
  background-color: whitesmoke;
  transform-origin: bottom right;
  transition: transform 0.25s ease-in;
  opacity: 0.9;
}

.tab:hover {
  background-color: transparent;
  font-weight: bold;
  color: whitesmoke;
  opacity: 0.9;
}

.tab:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.tab-focus {
  background-color: transparent;
  font-weight: bold;
  color: whitesmoke;
  border-bottom: 1px white solid;
}

.tab-focus:hover {
  background-color: transparent;
}

.dash-task {
  display: flex;
  width: 95%;
  border-bottom: hsla(0, 0%, 100%, 0.259) 0.01rem solid;
  align-items: center;
  gap: 0.6rem;
  padding: 0.4rem;
  cursor: default;
}

.dash-task:hover {
  background-color: transparent;
}

.dash-task-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.5rem;
  overflow-y: auto;
}

.task-project {
  border-radius: 2rem;
  padding: 0.2rem 0.4rem;
  font-size: 0.7rem;
  cursor: pointer;
}

.dash-project-dropdown {
  position: absolute;
  z-index: 999;
}

.color {
  height: 1.3rem;
  width: 1.3rem;
  border-radius: .2rem;
  cursor: pointer;
}

.set-color-li {
  position: relative;
}
