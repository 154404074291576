.card-container {
  display: flex;
  width: 100%;
  gap: 1rem;
  padding: 1rem;
}

.task-main-container {
  background-color: transparent;
  width: 84%;
  height: 85.5vh;
  max-height: 85.5vh;
  position: relative;
}

.card-info-wrapper {
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.4rem;
  border-radius: 0.5rem;
  flex-grow: 0.2;
}

.column-area,
.add-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 23%;
  width: 25%;
  padding: 0.6rem;
  border-radius: 0.4rem;
  background-color: var(--white-background);
}

.column-area:hover {
  box-shadow: var(--background-color) 0 0 0.3rem;
}

.card {
  /* box-shadow: var(--background-color) 0 0 .3rem; */

  /* align-items: center; */

  width: 100%;
  /* max-height: 100%; */
  height: 75vh;
  background-color: transparent;
  color: var(--white-background);
  border-radius: 0.3rem;
}

.card-category {
  width: 100%;
  font-size: 1.1rem;
  background-color: transparent;
  border: none;
  color: var(--main-pink);
  font-weight: bold;
  cursor: drag;
}

.delete-category {
  font-size: 0.8rem;
  color: red;
  cursor: pointer;
  opacity: 0.9;
  font-weight: bold;
  text-align: center;
}

.category-container {
  width: 75%;
}

.card-category:focus-visible {
  box-shadow: var(--main-pink) 0.07rem 0.07rem 0.15rem;
  outline: var(--main-pink) 0.01rem solid;
  padding: 0.2rem 0.5rem;
}

.add-task {
  background-color: transparent;
  align-self: center;
  border: none;
  cursor: pointer;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: var(--background-color);
  padding: 0.3rem;
  opacity: 0.7;
  width: 95%;
  margin: auto;
  margin-top: 0.5rem;
}

.add-task:hover {
  box-shadow: var(--main-pink) 0 0 0.3rem;
  opacity: 1;
}

.kanban-task-container {
  width: 95%;
  margin: auto;
  align-self: center;
  cursor: pointer;
  padding: 0.6rem;
  /* background-color: #4445468b; */
  background-color: #2a2e31;
  box-shadow: var(--background-color) 0 0 0.5rem;
  border-radius: 0.3rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: .3rem;
}

.kanban-task-container:hover {
  border: solid var(--main-pink) 0.01rem;
  box-shadow: var(--main-pink) 0 0 0.4rem;
  cursor: pointer;
}

.task-details {
  color: var(--dull-white);
  word-wrap: break-word;
  font-size: 0.8rem;
  width: 85%;
}

.task-wrapper {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  flex-grow: 0;
}

.board-purpose-nav {
  font-size: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input-header {
  display: flex;
  width: 95%;
}

#add-task,
#edit-task {
  background-color: transparent;
  border: none;
  box-shadow: none;
  width: 80%;
}
.scroll-wrapper {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
}

.task-ellipse {
  display: flex;
  position: absolute;
  width: auto;
  height: auto;
  align-items: center;
  padding: 0.4rem;
  justify-content: center;
  background-color: var(--background-color);
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  color: var(--dull-white);
  cursor: pointer;
  border: none;
  border-radius: 0.5rem;
  box-shadow: var(--dull-white) 0rem 0rem 0.1rem;
  z-index: 3;
  margin: auto;
}

.task-ellipse:hover {
  background-color: #454547;
}

.fa-circle-check {
  cursor: pointer;
  position: relative;
}

.task-complete {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: green;
  color: whitesmoke;
  border-radius: 50%;
  width: 0.9rem;
  height: 0.9rem;
  font-size: 0.6rem;
  font-weight: bold;
  cursor: pointer;
}

.not-complete {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: var(--dull-white);
  border-radius: 50%;
  border: solid 0.1rem var(--dull-white);
  width: 0.9rem;
  height: 0.9rem;
  font-size: 0.5rem;
  font-weight: bold;
  cursor: pointer;
}

.task-dropdown {
  background-color: var(--background-color);
  color: var(--dull-white);
  box-shadow: var(--dull-white) 0 0 0.2rem;
  border-radius: 0.2rem;
  padding: 0.2rem 0;
  position: absolute;
  opacity: 1;
  z-index: 5;
}

li:hover {
  background-color: #454547;
}

.task-li {
  padding: 0.2rem 0.6rem;
  cursor: pointer;
  font-size: 0.8rem;
}
.task-menu {
  position: relative;
}

.task-ellipse-portal {
  display: flex;
  position: absolute;
  width: auto;
  height: auto;
  align-items: center;
  padding: 0.4rem;
  justify-content: center;
  background-color: #454547;
  color: var(--dull-white);
  cursor: pointer;
  border: none;
  border-radius: 0.5rem;
  box-shadow: var(--dull-white) 0rem 0rem 0.08rem;
  z-index: 5;
  margin: auto;
}

.assignee-initials {
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
  font-size: .5rem;
  color: var(--background-color);
  background-color: var(--main-pink);
  position: relative;
  font-weight: bold;
}


.assignee {
  display: flex;
  align-items: center;
  gap: .3rem;
  font-size: .6rem;
  padding-top: .3rem;
  position: relative
}

.unassigned {
  border: dashed var(--background-color) .01rem;
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
  font-size: .5rem;
  color: var(--background-color);
  position: relative;
}

.kanban-task-container:hover .unassigned {
  color: var(--dull-white);
  border: dashed var(--dull-white) .01rem;

}

.unassigned:hover {
  background-color: #2a2e31;
}

.assign-member {
  position: absolute;
  background-color: var(--background-color);
  width: max-content;
  box-shadow: var(--dull-white) 0rem 0rem 0.08rem;
  border-radius: 0.3rem;


}

.assign-input {
  padding: .5rem;

}

.member-assign-li {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: .6rem;
  align-items: center;
  padding: .2rem .5rem;
  color: var(--dull-white)
}