@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}
#root {
  max-width: 100vmax;
  max-height: 100vh;
  height: 100vh;
}

:root {
  --background-color: #1d2124;
  /* --background-color: #222223; */
  /* --main-pink: #B96872; */
  --main-pink: #f56879;
  --dash-boders: #010101;
  --light-gray: #9e9fa0;
  --dull-white: #e0e0e1;
  --white-background: whitesmoke;

  /* Icon color choices */
  --green: #5da283;
  --light-pink: #fc979a;
  --red: #f06a6a;
  --purple: #b36bd4;
  --blue: #4573d2;
}


body {
  background-color: var(--background-color);
  color: var(--main-pink);
}


.hidden {
  display: none;
}

.single-project-detail-container {
  display: flex;


}

li {
  list-style: none;
  cursor: pointer;
}

::-webkit-scrollbar {
  display: none;
}

.errors {
  color: red;
  text-align: center;
  font-size: .8rem;
  font-weight: bold;
}

#modal-content {
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  padding: 0.6rem;
  border-radius: 0.4rem;
  border: 0.1rem solid var(--background-color);
  color: var(--background-color);
}

form {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: 25rem;
}

label {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 85%;
}

input {
  border: 0.03rem solid var(--main-pink);
  padding: 0.1rem 0.4rem;
  border-radius: 0.2rem;
}

input:focus-visible {
  box-shadow: var(--main-pink) 0.07rem 0.07rem 0.15rem;
  outline: none;

}

.edit-button,
.delete-button,
.set-color-li {
  opacity: 1;
  background-color: transparent;
  border: none;
  color: var(--dull-white);
  font-size: .8rem;
  cursor: pointer;
}

.edit-button:hover,
.delete-button:hover,
.set-color-li {
  color: var(--white-background)
}

.delete-button {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.dropdown-icon {
  position: relative;
  cursor: pointer;
}

textarea {
  resize: none;
}


.delete-form {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem 0;
  width: 93%;
}

.delete-warning {
  background-color: #f0b132;
  padding: 1rem;
  color: var(--dash-boders);
  border-radius: 0.2rem;
  font-size: 1rem;
  width: 93%;
  font-weight: bold;
}

#delete-button {
  background-color: red;
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
  padding: 0.3rem;
  border-radius: 0.2rem;
  font-size: 1rem;

}

#cancel-button {
  background-color: transparent;
  border: none;
  color: var(--main-pink);
  font-weight: bold;
  cursor: pointer;
  padding: 0.3rem;
  border-radius: 0.2rem;
  font-size: 1rem;
}

#cancel-button:hover {
  box-shadow: var(--main-pink) 0 0 0.2rem;
}

#delete-button:hover {
  box-shadow: var(--background-color) 0 0 0.2rem;
}


.delete-form-title {
  font-size: 1.7rem;
  padding: 0.5rem;
}