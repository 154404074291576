.image-upload {
  border: var(--main-pink) 2px dashed;
  width: 7rem;
  height: 7rem;
  border-radius: 100%;
  align-self: center;
  background-color: var(--white-background);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--text);
  font-size: 1rem;
  object-fit: contain;
  position: relative;
}

.image-upload-input {
  display: none;
}

.upload-name {
  text-align: center;
  color: var(--background-color);
  padding: 0.7rem;
  font-size: 0.8rem;
  word-break: break-all;
}

.image-label {
  align-self: center;
  padding: 0 0.7rem;
}

#preview-image {
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 60%;
}
