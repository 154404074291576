.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  transition: all 0.3s;
}

.switch::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  background-color: white;
  top: 1px;
  left: 1px;
  transition: all 0.3s;
}

input[type="checkbox"]:checked + .switch::after {
  transform: translateX(20px);
}

input[type="checkbox"]:checked + .switch {
  background-color: #06ce31;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.current-member-container {
  display: flex;
  gap: 0.5rem;
  width: 100%;
  height: max-content;
  align-self: center;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: var(--main-pink) 0 0 0.2rem;
}

.fa-user-shield,
.fa-shield {
  padding-top: 0.1rem;
  font-size: 0.5rem;
}

#member-form {
  display: flex;
  gap: 0.5rem;
  width: 100%;
}

.admin-permissions {
  font-size: 0.7rem;
}

.label-toggle-admin {
  display: flex;
  justify-content: space-between;
}

.member-admin {
  padding: 0.6rem;
}

.member-role-input {
  align-self: flex-start;
  padding-top: .5rem;
}

.member-search-choose {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 100px;
  padding: 0 0.4rem;
}

.chosen-member-wrapper {
  padding: 0 0.3rem;
  display: flex;
  width: 50%;
  flex-direction: column;
  /* align-items: center; */
  /* gap: .5rem */
}

.none-chosen {
  font-size: 0.9rem;
  max-width: 70%;
  opacity: 0.4;
  font-weight: bold;
  text-align: center;
}

.member-filter {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  width: 48%;
  /* padding-top: .5rem; */
}

.member-filter > input {
  width: 95%;
  cursor: pointer;

}
.member-filter > input:focus-visible {
  box-shadow: none;
}

.search-dropdown-container {
  position: relative;
  width: 95%;
  display: flex;
  justify-content: center;
  height: 80%;
}
.search-dropdown {
  border: 0.03rem solid var(--main-pink);
  border-top: none;
  top: -4%;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: white;
  z-index: 999;
}

.member-search-li {
  padding-left: .5rem;
}

.member-search-li:hover {
  background-color: #fdc1c8a6;
}