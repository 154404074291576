.side-container {
  background-color: var(--background-color);
  width: 17%;
  /* box-shadow: var(--white-background) 0 0 .3rem; */
  height: fit-content;
}

.title-dropdown {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 3.3rem;
  background-color: var(--main-pink);
  opacity: 0.8;
  color: var(--background-color);
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0 0.7rem;
  position: relative;
}

.title-dropdown-side {
  display: flex;
  align-items: center;
  gap: .5rem;
  /* justify-content: space-around; */
  height: 3.3rem;
  background-color: var(--main-pink);
  color: var(--background-color);
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0 0.7rem;
  position: relative;
}

.board-list-name {
  cursor: pointer;
  color: var(--dull-white);
  padding: 0.5rem;
  padding-left: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.8rem;
}

.nav-board-list {
  padding: 0.5rem 1rem 0.3rem 0.5rem;
}

.board-list-name:hover,
.my-tasks:hover,
.project-home:hover {
  background-color: #2a2e31;
}

.create-board {
  margin-left: auto;
  background-color: transparent;
  color: var(--dull-white);
  border: none;
  cursor: pointer;
}

.nav-board-list,
.user-nav {
  display: flex;
  color: var(--dull-white);
}

.my-tasks,
.project-home {
  background-color: transparent;
  color: var(--dull-white);
  border: none;
  font-size: 0.9rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
}

.dd-project-side-nav {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-nav {
  box-shadow: var(--dull-white) 0 0.01rem;
  flex-direction: column;
  padding: 0.5rem 0;
}

.dropdown {
  position: absolute;
  left: 0;
  box-shadow: var(--dull-white) 0 0 0.2rem;
  background-color: var(--background-color);
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  width: max-content;
  gap: 0.6rem;
  z-index: 2;
  border-radius: 0.2rem;
}

.dropdown > li {
  line-height: 1rem;
}

.fa-caret-down {
  cursor: pointer;
}

.side-nav-menu {
  position: relative;
  margin-left: auto;
}

.side-project-icon {
  font-size: .9rem;
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  border-radius: .5rem;
}
