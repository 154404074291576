.owner-warning {
    background-color: #f0b132;
    padding: 1rem;
    color: var(--dash-boders);
    border-radius: 0.2rem;
    font-size: 1rem;
    width: 93%;
}

#transfer-button {
    background-color: var(--main-pink);
    border: none;
    color: var(--background-color);
    font-weight: bold;
    cursor: pointer;
    padding: 0.3rem;
    border-radius: 0.2rem;
    font-size: 1rem;
}