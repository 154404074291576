.main-container {
  background-color: transparent;
  width: 84%;
  height: 85.5vh;
  position: relative;
}

.under-nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--white-background);
  height: 100%;
  padding: 0 1.5rem;
  gap: 2rem;
}

.resource {
  border: var(--background-color) dashed 0.1rem;
  color: var(--background-color);
  padding: 0.8rem;
  border-radius: 0.7rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resource:hover {
  border: var(--main-pink) dashed 0.1rem;

}

.add-resource {
    display: flex;
    background-color: transparent;
    border: none;
    align-items: center;
    gap: .3rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: .3rem;
    opacity: .6;
}

.resource:hover,
.add-resource:hover {
    font-weight: bold;
    opacity: 1;
    color: var(--main-pink)
}

.resources-wrapper {
    width: 100%;
    padding: 0 1rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.project-nav {
  border-bottom: var(--main-pink) solid 0.1rem;
  background-color: #ffffff0f;
  height: 3.3rem;
  color: var(--white-background);
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1.5rem;
}

.resource-container {
  height: 2.5rem;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--background-color) 0 0 .2rem;
  padding: .5rem;
  border-radius: .5rem;
  position: relative;
}

.delete-resource {
  color: var(--background-color);
  opacity: 0;
  cursor: pointer;
  font-size: .6vw;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: .3vw;
  padding-top: .3vw;
}

.resource-container:hover .delete-resource {
  color: rgba(74, 73, 73, 0.49);
  opacity: 1;
  z-index: 2;
}

.resource-link {
    height: 2.5rem;
    width: 100%;
    padding: .5rem;
    text-decoration: none;
    color: var(--main-pink);
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    gap: .8vw;
}
.resource-container:hover {
    font-weight: bold;
}

.project-details-container {
  background-color: #f5c1c8;
  opacity: 0.9;
  color: var(--background-color);
  width: 100%;
  height: 10rem;
  padding: 1.5rem;
  border-radius: 0.5rem;
  align-self: center;
}
.team-container {
  color: var(--background-color);
  justify-self: flex-start;
}

.context-title {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  font-size: 1.5rem;
  color: var(--background-color);
}

.team-title {
  padding-bottom: 0.6rem;
  font-size: 1.5rem;
  color: var(--background-color);
  padding-left: 0.5rem;
}

.member-container {
  display: flex;
  gap: 0.5rem;
  height: auto;
  align-items: center;
  cursor: pointer;
  padding: .5rem;
  border-radius: .5rem;
  position: relative;
}

.member-container:hover {
  box-shadow: var(--main-pink) 0 0 .2rem;
}


.member-details {
  line-height: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 1px;
}

.member-name {
  font-size: 0.9rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.member-role {
  font-size: 0.7rem;
  opacity: 0.7;
  display: flex;
  gap: .2rem;
}

.single-project-title {
  font-size: 1.4rem;
}

.no-resources {
  display: flex;
  box-shadow: var(--background-color) 0 0 .3rem;
  padding: .4rem;
  margin: auto;
  width: 100%;
  justify-content: center;
  color: var(--background-color);
  align-items: center;
  border-radius: .5rem;
}

.resource-icon {
  width: 10rem;
}

.add-first-resource {
  display: flex;
  margin: auto;
  align-items: center;
  background-color: transparent;
  border: none;
  opacity: .7;
  font-size: 1rem;
  padding: .5rem;
  cursor: pointer;
}

.add-first-resource:hover {
  opacity: 1;
  font-weight: bold;
}

.members-wrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-left: .8rem
}

.fa-angle-down {
  font-size: .8rem;
  color: var(--white-background)
}

.member-container:hover .fa-angle-down {
  color: var(--background-color)
}

.member-menu-container {
  position: relative;
}

.member-menu {
  position: absolute;
  background-color: var(--background-color);
  color: var(--dull-white);
  border-radius: .3rem;
  padding: .2rem 0;
  max-width: max-content;
}

.member-menu-li {
  width: 100%;
  padding: 0 .5rem;
  font-size: .8rem;
}

.add-team-member {
  background-color: transparent;
  border: none;
  color: var(--background-color);
  padding: .5rem;
  border-radius: .5rem;
  font-size: 1rem;
  cursor: pointer;
  opacity: .7;
  display: flex;
  align-items: center;
  gap: .5rem
}

.team {
  border: dashed var(--background-color) .1rem;
  border-radius: 0.7rem;
  padding: .65rem;
}

.add-team-member:hover {
  opacity: 1;
  color: var(--main-pink);
}

.add-team-member:hover .team {
  border: dashed var(--main-pink) .1rem;

}

.role-dropdown {
  position: absolute;
  background-color: var(--background-color);
  color: var(--dull-white);
  border-radius: .3rem;
  padding: .5rem;
  max-width: max-content;
}

.role-form {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.role-submit {
  background-color: var(--main-pink);
  border: none;
  align-self: center;
  padding: 0 0.3rem;
  margin-top: 0.5rem;
  border-radius: 0.2rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.role-input {
  width: 100%;
  font-size: .8rem;
  box-shadow: var(--light-gray) 0rem 0rem .1rem;
  border: none;
  background-color: var(--background-color);
  color: var(--dull-white);
}

.role-input:focus-visible {
  box-shadow: var(--light-gray) 0rem 0rem .3rem;

}

.role-input-header {
  font-size: .8rem;
  padding-bottom: .4rem;
}

.resource-favicon {
  width: auto;
}